








































import Vue from 'vue'
// eslint-disable-next-line
import VueAwesomeSwiper from 'vue-awesome-swiper'
// eslint-disable-next-line
import {swiper,swiperSlide} from 'vue-awesome-swiper'
// require styles
import 'swiper/dist/css/swiper.css'
import PSingleCard from './PSingleCard.vue'
import VueMaterial from 'vue-material/dist/vue-material'
import { MdButton, MdCard, MdIcon } from 'vue-material/dist/components'
declare function require(name:string): any
var MobileDetect = require('mobile-detect')
var md = new MobileDetect(window.navigator.userAgent)

Vue.use(VueAwesomeSwiper /* { default global options } */)
Vue.use(VueMaterial)
Vue.use(MdButton)
Vue.use(MdCard)
Vue.use(MdIcon)

export default {
  name: 'PSubSection',
  props: {
    imagesAndDescriptions: {
      type: Array,
      default: () => [{ 'img': 'img/logo.png', 'description': 'DefaultImgDescription' }]
    }
  },
  components: {
    swiper,
    swiperSlide,
    PSingleCard
  },
  data () {
    return {
      width: md.mobile() ? 250 : 450,
      swiperOption: {
        // Disable preloading of all images
        preloadImages: false,
        // Enable lazy loading
        lazy: false,
        watchSlidesVisibility: true,
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: 'auto',
        freeMode: true,
        spaceBetween: 25,
        slidesOffsetBefore: md.mobile() ? 0 : -60,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }
      }
    }
  }
}
